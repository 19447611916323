<template>
    <base-form
        ref="form"
        v-model="form"
        :api="api"
        :mapper="mapper"
        :entity-id="entityId"
        :disabled="disabled"
        :disable-save-button="disableSaveButton"
        :disable-delete-button="true"
        no-page-title
        @created="created"
        @updated="updated"
        @cancelled="cancelled"
        @deleted="deleted"
    >
        <!-- RESTAURANT SELECTOR -->
        <restaurant-selector
            v-if="isRestaurantAdmin"
            class="mb-4"
        ></restaurant-selector>
        <page-title>
            {{ form.name }}
            <edit-button
                v-if="form.slug"
                :tooltip="$t('labels.copy_frontend_url')"
                class="ml-3"
                icon="content_copy"
                small
                @execute="copyFElink()"
            ></edit-button>
        </page-title>
        <form-tabs
            v-model="activeTab"
            :hide-header="businessHoursPage"
            :class="['pb-16']"
        >
            <form-tab
                v-for="tab in tabs"
                v-show="!businessHoursPage"
                :key="tab.name"
                :tab-errors="tabErrors"
                :name="tab.name"
                :title="tab.title"
                :disabled="!!tab.disabled"
            ></form-tab>

            <v-tabs-items
                :key="`tabsContent-${tabs ? tabs.length : '0'}`"
                v-model="activeTab"
            >
                <component
                    :is="tab.component"
                    v-for="tab in tabs"
                    :key="`tabItem-${tab.name}`"
                    v-model="form"
                    @tabError="
                        tabErrorsContainer = {
                            ...tab.tabError,
                            ...$event,
                        }
                    "
                >
                </component>
            </v-tabs-items>
        </form-tabs>
    </base-form>
</template>

<script>
import routeType from '@/router/routeType'
import paramType from '@/router/paramType'
import RestApiType from '@/api/RestApiType'
import MapperType from '@/services/mapper/MapperType'
import RestaurantDeliveryAreaType from '@/enum/RestaurantDeliveryAreaType'
import RestaurantPriceCategoryType from '@/enum/RestaurantPriceCategoryType'
import { ROLE_ADMIN, ROLE_RESTAURANT } from '@/enum/roles'
import { copyRestaurantFElink } from '@/services/Restaurants/RestaurantFrontendLink'

import PageTitle from '@/components/layout/components/pageTitle'
import EditButton from '@/components/table/buttons/EditButton'
import FormTabs from '@/components/form/FormTabs'
import FormTab from '@/components/form/FormTab'
import RestaurantSelector from '@/components/layout/components/RestaurantSelector'
import GeneralTab from './tabs/General'
import InfoTab from './tabs/Info'
import OpenHoursTab from './tabs/OpenHours'
import TokenTab from './tabs/Token'
import DeliverySettingsTab from './tabs/DeliverySettings'
import OrderTransferTab from './tabs/OrderTransfer'
import MenuTab from './tabs/Menu'
import MealOptionGroupsTab from './tabs/MealOptionGroups'
import MealImages from './tabs/MealImages'

import AuthMixin from '@/components/mixins/AuthMixin'
import FormBase from '@/components/mixins/FormBase'
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import { EventBus } from '@/plugins/events'
import { getPPiClone } from '@/services/Restaurants/PersonalPickupInformation'
import { getPiClone } from '@/services/Restaurants/PickupInformation'

export default {
    components: {
        PageTitle,
        EditButton,
        RestaurantSelector,
        FormTabs,
        FormTab,
        GeneralTab,
        InfoTab,
        OpenHoursTab,
        TokenTab,
        DeliverySettingsTab,
        OrderTransferTab,
        MenuTab,
        MealOptionGroupsTab,
        MealImages,
    },

    mixins: [FormBase, AuthMixin],

    data() {
        return {
            api: RestApiType.RESTAURANTS,
            mapper: MapperType.RESTAURANT_FORM,
            form: {
                name: '',
                phone: '',
                tags: [],
                translations: {},
                isActive: true,
                isVisibleForCompany: true,
                isVisibleForPublic: true,
                areas: [],
                variantAreas: [],
                billingInfo: {
                    regCompanyName: null,
                    registrationNumber: null,
                    regAddressCountry: null,
                    regAddressPostcode: null,
                    regAddressStreetNumber: null,
                    regAddressStreet: null,
                    accountNumber: null,
                    sortCode: null,
                    vatNumber: null,
                    commOnFood: null,
                    commOnDelivery: null,
                    cardTransactionFee: null,
                    restaurantCommOnFood: null,
                    restaurantCommOnDelivery: null,
                    restaurantCardTransactionFee: null,
                    billingContacts: [{ name: '', email: '', phone: '' }],
                    orderContacts: [{ name: '', email: '', phone: '' }],
                    paymentTerm: null,
                    selfBilling: null,
                },
                openingDays: [],
                specialClosures: [],
                location: '',
                longitude: null,
                latitude: null,
                isPreorder: false,
                isPreorderDiffCalendar: false,
                preorderBorderTime: '00:00',
                preorderDayBefore: 1,
                preorderDayUntil: 60,
                deliveryAreaType: RestaurantDeliveryAreaType.custom,
                orditSoftwareSetting: null,
                priceCategory: RestaurantPriceCategoryType.average,
                isCatering: null,
                exportEnabled: true,
                hiddenCompanies: [],
                isOpeningHoursEditEnabled: true,
                coverImageMedia: null,
                cardImageMedia: null,
                approveEmail: null,
                additionalApproveEmails: [],
                accessToken: null,
                timezoneRegion: null,
                pickupInformation: getPiClone(),
                deliverySetting: {
                    defaultPreparationTime: null,
                    deliveryRules: [],
                },
                isEnabledForRestaurantAdmin: true,
                sendOnlyCanteenConfirmEmail: false,
                canteenLargeCutoffNotice: null,
                canteenLargeEarliestPickup: null,
                canteenLargeLatestCutoff: null,
                canteenLargeLatestPickup: null,
                canteenSmallCutoffNotice: null,
                canteenSmallEarliestPickup: null,
                canteenSmallLatestCutoff: null,
                canteenSmallLatestPickup: null,
                canteenSmallOrderLimit: null,
                deliverooUrl: null,
                printQRCodeOn: ['receipt'],
                defaultCanteenRules: [],
                thirdPartyType: null,
                city: null,
                printConfig: {
                    print_order_receipt: true,
                },
                canteenPrintConfig: {
                    print_individual_receipt: true,
                    print_summary_receipt: false,
                    show_dymo_alert_modal: false,
                },
                personalPickupInformation: getPPiClone(),
                hasDymoPrinter: false,
                inactivatedUntil: null,
            },
            editRoute: routeType.RESTAURANT_EDIT,
            listRoute: routeType.RESTAURANT_LIST,
        }
    },
    computed: {
        ...mapGetters({
            isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
        }),
        tabs() {
            return [
                {
                    name: 'general',
                    title: this.$t('labels.general_settings'),
                    roles: [ROLE_ADMIN],
                    component: GeneralTab,
                    tabError: this.tabErrorsContainer,
                },
                {
                    name: 'info',
                    title: this.$t('labels.info'),
                    roles: [ROLE_ADMIN],
                    component: InfoTab,
                    tabError: this.tabErrorsContainer,
                },
                {
                    name: 'businesshours',
                    title: this.$t('labels.business_hours'),
                    roles: [
                        ROLE_ADMIN,
                        this.businessHoursPage ? ROLE_RESTAURANT : null,
                    ],
                    component: OpenHoursTab,
                    tabError: this.tabErrorsContainer,
                },
                {
                    name: 'deliverySettings',
                    title: this.$t('labels.delivery_settings'),
                    roles: [ROLE_ADMIN],
                    component: DeliverySettingsTab,
                    tabError: this.tabErrorsContainer,
                },
                {
                    name: 'orderTransfer',
                    title: this.$t('labels.order_transfer'),
                    disabled: !this.form['@id'],
                    roles: [ROLE_ADMIN],
                    component: OrderTransferTab,
                    tabError: this.tabErrorsContainer,
                },
                {
                    name: 'menu',
                    title: this.$t('labels.menu'),
                    disabled: !this.form['@id'],
                    roles: [ROLE_ADMIN, this.menuPage ? ROLE_RESTAURANT : null],
                    component: MenuTab,
                    tabError: this.tabErrorsContainer,
                },
                {
                    name: 'mealOptionGroups',
                    title: this.$t('labels.meal_option_groups'),
                    disabled: !this.form['@id'],
                    roles: [ROLE_ADMIN, this.menuPage ? ROLE_RESTAURANT : null],
                    component: MealOptionGroupsTab,
                    tabError: this.tabErrorsContainer,
                },
                {
                    name: 'mealImages',
                    title: this.$t('labels.meal_images'),
                    disabled: !this.form['@id'],
                    roles: [ROLE_ADMIN],
                    component: MealImages,
                    tabError: {},
                },
                {
                    name: 'token',
                    title: this.$t('labels.token'),
                    disabled: !this.form['@id'],
                    roles: [ROLE_ADMIN],
                    component: TokenTab,
                    tabError: {},
                },
            ].filter((tabItem) => {
                let enabled = true

                if (tabItem.roles) {
                    const roleIntersection = tabItem.roles.filter((item) =>
                        this.userRoles.includes(item)
                    )

                    enabled = roleIntersection.length > 0
                }
                return enabled
            })
        },

        businessHoursPage() {
            return (
                this.isRestaurantAdmin &&
                this.$route.params.page === paramType.RESTAURANT_BUSINESS_HOURS
            )
        },

        menuPage() {
            return (
                this.isRestaurantAdmin &&
                this.$route.params.page === paramType.RESTAURANT_MENU
            )
        },

        disableSaveButton() {
            return (
                this.activeTab > 4 ||
                (this.isRestaurantAdmin && !this.businessHoursPage)
            )
        },

        tabErrors() {
            const tabErrors = {}
            Object.keys(this.tabErrorsContainer).forEach((tabName) => {
                Object.keys(this.tabErrorsContainer[tabName]).forEach(
                    (propertyName) => {
                        if (!tabErrors[tabName]) {
                            tabErrors[tabName] = 0
                        }
                        if (
                            this.tabErrorsContainer[tabName][propertyName] > 0
                        ) {
                            tabErrors[tabName]++
                        }
                    }
                )
            })
            return tabErrors
        },

        activeTab: {
            get() {
                return parseInt(this.$route.query.tab) || 0
            },
            set(val) {
                this.$router.replace({ query: { tab: val } })
            },
        },
    },

    mounted() {
        if (this.isRestaurantAdmin)
            EventBus.$on('reloadRestaurant', this.$refs.form.load)
    },

    beforeDestroy() {
        if (this.isRestaurantAdmin)
            EventBus.$off('reloadRestaurant', this.$refs.form.load)
    },

    methods: {
        copyFElink() {
            copyRestaurantFElink(this.form)
        },
    },
}
</script>
