import FormInput from '@/components/mixins/FormInput'

import SelectApiClientType from '@/api/SelectApiClientType'
import selectapiType from '@/store/type/selectapiType'

import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import FormTabMixin from '@/components/mixins/FormTabMixin'

export default {
    components: {
        EntitySelectInput,
    },

    mixins: [FormInput, FormTabMixin],

    data() {
        return {
            selectApiClientType: SelectApiClientType,
            suitableForConstraints: {},
        }
    },

    computed: {
        invalidAllergens() {
            let invalidAllergens = []
            if (
                this.suitableForConstraints?.invalid_allergens &&
                this.model.suitableFor
            ) {
                this.model.suitableFor.map((el) => {
                    let key = Object.keys(
                        this.suitableForConstraints.invalid_allergens
                    ).find((key) => {
                        return key == el
                    })
                    if (key)
                        invalidAllergens = [
                            ...invalidAllergens,
                            ...this.suitableForConstraints.invalid_allergens[
                                key
                            ],
                        ]
                })
            }
            return invalidAllergens
        },

        invalidAdditionals() {
            let invalidAdditionals = []
            if (
                this.suitableForConstraints?.invalid_additionals &&
                this.model.suitableFor
            ) {
                this.model.suitableFor.map((el) => {
                    let key = Object.keys(
                        this.suitableForConstraints.invalid_additionals
                    ).find((key) => {
                        return key == el
                    })
                    if (key)
                        invalidAdditionals = [
                            ...invalidAdditionals,
                            ...this.suitableForConstraints.invalid_additionals[
                                key
                            ],
                        ]
                })
            }
            return invalidAdditionals
        },
    },

    watch: {
        'model.suitableFor': {
            handler(suitableFor) {
                if (suitableFor?.length) {
                    if (this.invalidAllergens?.length) {
                        this.$set(
                            this.model,
                            'allergens',
                            this.model.allergens?.filter((el) => {
                                return this.invalidAllergens.indexOf(el) === -1
                            })
                        )
                    }
                    if (this.invalidAdditionals?.length) {
                        this.$set(
                            this.model,
                            'additional',
                            this.model.additional?.filter((el) => {
                                return (
                                    this.invalidAdditionals.indexOf(el) === -1
                                )
                            })
                        )
                    }
                }
            },
            immediate: true,
        },
    },

    mounted() {
        this.$store
            .dispatch(
                selectapiType.getActionName(
                    this.selectApiClientType.SUITABLE_FOR_CONSTRAINTS,
                    selectapiType.actions.LOAD
                )
            )
            .then((res) => {
                this.suitableForConstraints = res
            })
    },
}
