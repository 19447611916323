<script>
import Cropper from '@/components/elements/Cropper'
import SelectInput from '@/components/form/Inputs/SelectInput'

export default {
    components: {
        SelectInput,
        Cropper,
    },
    props: {
        value: Object,
        name: String,
        disabledMeals: Array,
        meals: null,
        requestErrors: Array,
        disabled: Boolean,
        restaurant: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            meal: null,
            imageHeight: 129,
        }
    },
    computed: {
        imageError() {
            return this.requestErrors.find((error) => error.field === this.name)
                ?.msg
        },
        coverError() {
            return !!this.requestErrors.find(
                (error) => error.field === this.name && error.type === 'cover'
            )
        },
        thumbnailError() {
            return !!this.requestErrors.find(
                (error) =>
                    error.field === this.name && error.type === 'thumbnail'
            )
        },
        errorMessages() {
            let imageError = []
            if (this.imageError) imageError = this.imageError

            return [
                ...imageError,
                ...this.errors.collect('mealImage_' + this._uid),
            ]
        },
    },
    watch: {
        'value.cover': {
            immediate: true,
            handler(cover, oldCover) {
                if (cover !== oldCover && !this.value.meal) {
                    let name = this.value?.cover?.name?.split('.')?.[0]
                    if (name && this.meals && !this.value?.id) {
                        let match = this.meals?.find((meal) => {
                            return (
                                meal.name?.toLowerCase() ===
                                    name?.toLowerCase() ||
                                Number.parseInt(meal.id) ===
                                    Number.parseInt(name) ||
                                meal.code?.toLowerCase() === name?.toLowerCase()
                            )
                        })
                        if (match) {
                            this.$emit('input', {
                                ...this.value,
                                ...{ meal: match.key },
                            })
                        }
                    }
                }
            },
        },
    },
    methods: {
        update(type) {
            if (this.name) {
                let index = this.requestErrors.findIndex(
                    (error) => error.field === this.name && error.type === type
                )
                if (index >= 0) {
                    let requestErrors = this.requestErrors
                    requestErrors.splice(index, 1)
                    this.$emit('update:requestErrors', requestErrors)
                }
            }

            this.$emit('input', this.value)
        },
    },
}
</script>

<template lang="pug">
    v-card.meal-image-editor
        .d-flex(:style="{ height:`${imageHeight}px` }")
            cropper.w-50(:file.sync="value.cover" :disabled="disabled" :error="coverError" @update:file="update( 'cover')" :height="imageHeight" :size="{ height: 315, width: 605 }" :aspect-ratio="1.920635")
            cropper.w-50(:file.sync="value.thumbnail" :disabled="disabled" :error="thumbnailError" @update:file="update('thumbnail')" :height="imageHeight" :size="{ height: 160, width: 160 }" :aspect-ratio="1")
        v-card-actions
            select-input(
                v-model="value.meal"
                label="labels.meal"
                item-text="name"
                item-value="key"
                :disabled="disabled"
                :items="meals"
                :item-disabled="(item) => disabledMeals.includes(item.key) && item.key !== value.meal"
                :name="'mealImage_' + _uid"
                :data-vv-as="$t('labels.meal')"
                :error-messages="errorMessages"
                :placeholder="$t('labels.no_matching_meal')"
                @input="update"
                clearable
                persistent-placeholder
                autocomplete)
</template>

<style scoped lang="scss"></style>
