<script>
import mealType from '@/store/type/mealType'
import FormInput from '@/components/mixins/FormInput'
import FormTabMixin from '@/components/mixins/FormTabMixin'

import SelectApiClientType from '@/api/SelectApiClientType'
import MapperCollection from '@/services/mapper/MapperCollection'
import MapperType from '@/services/mapper/MapperType'
import RestApiCollection from '@/api/RestApiCollection'
import RestApiType from '@/api/RestApiType'
import selectapiType from '@/store/type/selectapiType'

import TextInput from '@/components/form/Inputs/TextInput'
import TextareaInput from '@/components/form/Inputs/TextareaInput'
import MarkdownInput from '@/components/form/Inputs/MarkdownInput'

import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'

import FormTwoColRow from '@/components/form/FormTwoColRow'
import FormPanel from '@/components/form/FormPanel'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'
import { mapGetters } from 'vuex'
import authType from '@/store/type/authType'
import ImageEditor from '@/components/pages/meals/ImageEditor'
import Draggable from 'vuedraggable'
import IdFromIri from '@/services/IdFromIri'
import Allergen from '@/components/pages/meals/mixins/Allergen'

export default {
    components: {
        ImageEditor,
        MarkdownInput,
        TextInput,
        TextareaInput,
        EntitySelectInput,
        FormTwoColRow,
        FormPanel,
        ConfirmModal,
        CheckboxInput,
        Draggable,
    },

    mixins: [FormInput, FormTabMixin, Allergen],

    data() {
        return {
            isValid: false,
            selectApiClientType: SelectApiClientType,
            api: RestApiType.MEAL,
            dialog: false,
            mapper: MapperType.MEAL_FORM,
            optionGroupNames: [],
        }
    },

    computed: {
        ...mapGetters({
            isRestaurantAdmin: authType.getters.IS_RESTAURANT_ADMIN,
        }),
        customGroupOrder: {
            get() {
                return this.model.groupsOrder !== null
            },
            set(val) {
                this.model.groupsOrder = val ? this.fillGroupsOrder() : null
            },
        },

        customGroupOrderEnabled() {
            return this.model.groups !== null && this.model.groups?.length > 1
        },
    },

    watch: {
        'model.restaurant': {
            handler(restaurant) {
                if (!restaurant) return

                this.$store
                    .dispatch(
                        selectapiType.getActionName(
                            this.selectApiClientType.MEAL_OPTION_GROUP,
                            selectapiType.actions.LOAD
                        ),
                        { query: { restaurant } }
                    )
                    .then((res) => {
                        this.optionGroupNames = res
                    })
            },
            immediate: true,
        },
    },

    methods: {
        back() {
            this.$router.push(
                {
                    query: { ...this.$route.query, meal: undefined },
                },
                () => {
                    this.$store.dispatch(mealType.actions.SET, {})
                }
            )
        },

        setThumbnail() {
            this.model.thumbMedia = this.model.imageMedia
        },

        deleteMeal() {
            RestApiCollection.get(this.api)
                .delete(this.model.id)
                .then(() => {
                    this.back()
                })
        },

        save() {
            const entity = MapperCollection.get(this.mapper).toEntity(
                this.model
            )

            if (this.model.id) {
                RestApiCollection.get(this.api)
                    .update(entity, this.model.id)
                    .then(() => {
                        this.success()
                    })
            } else {
                RestApiCollection.get(this.api)
                    .create(entity)
                    .then(() => {
                        this.success()
                    })
            }
        },

        success() {
            this.$store.dispatch(mealType.actions.SET, {})
            this.$router.push({
                query: { ...this.$route.query, meal: undefined },
            })
        },

        onGroupsChange() {
            if (Array.isArray(this.model.groupsOrder)) {
                if (this.model.groups.length > this.model.groupsOrder.length) {
                    this.model.groupsOrder.push(
                        this.groupsOrder(
                            this.model.groups[this.model.groups.length - 1],
                            this.model.groups.length
                        )
                    )
                } else if (this.model.groups.length < 2) {
                    this.model.groupsOrder = null
                } else {
                    this.model.groupsOrder = this.fillGroupsOrder()
                }
            }
        },

        groupName(id) {
            return this.optionGroupNames.find((x) => x.id === id)?.label
        },

        fillGroupsOrder() {
            return this.model.groups.map((iri, i) =>
                this.groupsOrder(iri, i + 1)
            )
        },

        groupsOrder(iri, position) {
            return {
                id: parseInt(IdFromIri.getId(iri)),
                position,
            }
        },

        onDragEnd() {
            this.model.groupsOrder.forEach((el, i) => {
                el.position = i + 1
            })
        },
    },
}
</script>

<template>
    <div class="meal-page">
        <div class="my-4">
            <v-btn color="primary" small class="ml-2" @click="back">
                {{ $t('actions.back') }}
            </v-btn>
            <v-form v-model="isValid">
                <form-panel :title="$t('labels.general_settings')">
                    <form-two-col-row>
                        <template v-slot:col1>
                            <text-input
                                v-model="model.name"
                                v-tab-error:menu
                                v-validate.immediate="'required|space'"
                                :required="true"
                                name="name"
                                label="labels.name"
                                :error-messages="errors.collect('name')"
                                :data-vv-as="$t('labels.name')"
                            ></text-input>
                        </template>
                        <template v-slot:col2>
                            <text-input
                                v-if="isRestaurantAdmin"
                                v-model="model.restaurantPrice"
                                v-tab-error:menu
                                v-validate="'required|decimal:2'"
                                name="price"
                                label="labels.price"
                                :error-messages="errors.collect('price')"
                                :data-vv-as="$t('labels.price')"
                            ></text-input>
                            <text-input
                                v-else
                                v-model="model.price"
                                v-tab-error:menu
                                v-validate="'required|decimal:2'"
                                name="price"
                                label="labels.price"
                                :error-messages="errors.collect('price')"
                                :data-vv-as="$t('labels.price')"
                            ></text-input>
                        </template>
                    </form-two-col-row>
                    <form-two-col-row v-if="!isRestaurantAdmin">
                        <template v-slot:col1>
                            <text-input
                                v-model="model.discountPrice"
                                v-tab-error:menu
                                name="discountPrice"
                                label="labels.discountPrice"
                                :error-messages="
                                    errors.collect('discountPrice')
                                "
                                :data-vv-as="$t('labels.discountPrice')"
                            ></text-input>
                            <text-input
                                v-model="model.supplierPrice"
                                v-tab-error:menu
                                name="supplierPrice"
                                label="labels.supplier_price"
                                :error-messages="
                                    errors.collect('supplierPrice')
                                "
                                :data-vv-as="$t('labels.supplier_price')"
                            ></text-input>
                        </template>
                        <template v-slot:col2>
                            <text-input
                                v-model="model.minimumQuantity"
                                v-validate="'required|numeric'"
                                v-tab-error:menu
                                name="minimumQuantity"
                                label="labels.minimum_quantity"
                                :error-messages="
                                    errors.collect('minimumQuantity')
                                "
                                :data-vv-as="$t('labels.minimum_quantity')"
                            ></text-input>
                        </template>
                    </form-two-col-row>
                    <markdown-input
                        v-model="model.description"
                        v-validate="'space'"
                        v-tab-error:menu
                        name="description"
                        label="labels.description"
                        class="mt-4"
                        :error-messages="errors.collect('description')"
                        :data-vv-as="$t('labels.description')"
                    ></markdown-input>
                    <textarea-input
                        v-if="!isRestaurantAdmin"
                        v-model="model.ingredients"
                        v-validate="'space'"
                        v-tab-error:menu
                        name="ingredients"
                        label="labels.ingredients"
                        class="mt-4"
                        :error-messages="errors.collect('ingredients')"
                        :data-vv-as="$t('labels.ingredients')"
                    ></textarea-input>
                    <text-input
                        v-model="model.code"
                        v-tab-error:menu
                        v-validate="'space'"
                        name="code"
                        label="labels.code"
                        :error-messages="errors.collect('code')"
                        :data-vv-as="$t('labels.code')"
                    ></text-input>
                    <text-input
                        v-if="!isRestaurantAdmin"
                        v-model.number="model.deliveryUnit"
                        v-tab-error:menu
                        v-validate.immediate="'required|numeric|min_value:0'"
                        name="deliveryUnit"
                        label="labels.delivery_unit"
                        :error-messages="errors.collect('deliveryUnit')"
                        :data-vv-as="$t('labels.delivery_unit')"
                    ></text-input>
                    <entity-select-input
                        v-if="model.restaurant"
                        v-model="model.category"
                        v-tab-error:menu
                        v-validate.immediate="'required'"
                        label="labels.meal_category"
                        name="mealCategory"
                        :query-params="{ restaurant: model.restaurant }"
                        :entity="selectApiClientType.MEAL_CATEGORY"
                        :data-vv-as="$t('labels.meal_category')"
                        :error-messages="errors.collect('mealCategory')"
                    ></entity-select-input>
                    <checkbox-input
                        v-model="model.isActive"
                        label="labels.active"
                        name="isActive"
                    ></checkbox-input>
                    <text-input
                        v-model="model.kcal"
                        v-tab-error:menu
                        v-validate="'space|integer'"
                        name="kcal"
                        label="labels.kcal"
                        :error-messages="errors.collect('kcal')"
                        :data-vv-as="$t('labels.kcal')"
                    ></text-input>
                </form-panel>

                <form-panel
                    v-if="!isRestaurantAdmin"
                    :title="$t('labels.images')"
                >
                    <image-editor
                        v-model="model"
                        :primary-size="{ height: 315, width: 605 }"
                        :secondary-size="{ height: 160, width: 160 }"
                        primary-key="imageMedia"
                        secondary-key="thumbMedia"
                        primary-label="labels.image_media"
                        secondary-label="labels.thumb_media"
                    ></image-editor>
                </form-panel>

                <form-panel :title="$t('labels.vat')">
                    <entity-select-input
                        v-model="model.valueAddedTax"
                        v-tab-error:menu
                        v-validate.immediate="'required'"
                        label="labels.vat"
                        name="valueAddedTax"
                        :entity="selectApiClientType.VAT"
                        :data-vv-as="$t('labels.vat')"
                        :error-messages="errors.collect('valueAddedTax')"
                    ></entity-select-input>
                </form-panel>

                <form-panel
                    v-if="model && model.restaurant"
                    :title="$t('labels.meal_option_groups')"
                >
                    <entity-select-input
                        v-if="model.restaurant"
                        v-model="model.groups"
                        v-tab-error:menu
                        v-validate="''"
                        label="labels.meal_option_groups"
                        name="meal_option_groups"
                        :entity="selectApiClientType.MEAL_OPTION_GROUP"
                        :data-vv-as="$t('labels.meal_option_groups')"
                        :error-messages="errors.collect('meal_option_groups')"
                        :query-params="{ restaurant: model.restaurant }"
                        multi-select
                        @input="onGroupsChange"
                    ></entity-select-input>
                    <checkbox-input
                        v-model="customGroupOrder"
                        :disabled="!customGroupOrderEnabled"
                        label="labels.custom_option_groups_order"
                        name="customOrder"
                    ></checkbox-input>
                    <v-list v-if="customGroupOrder">
                        <draggable
                            v-model="model.groupsOrder"
                            handle=".handle"
                            @end="onDragEnd"
                        >
                            <v-list-item
                                v-for="(item, index) in model.groupsOrder"
                                :key="item.id"
                                class="c-pointer show-item-on-hover"
                            >
                                <v-list-item-title class="handle">
                                    <v-icon> drag_indicator</v-icon>
                                    {{ index + 1 + '. ' + groupName(item.id) }}
                                </v-list-item-title>
                            </v-list-item>
                        </draggable>
                    </v-list>
                </form-panel>

                <form-panel :title="$t('labels.allergens')">
                    <entity-select-input
                        v-model="model.suitableFor"
                        v-tab-error:menu
                        v-validate="''"
                        type="checkbox"
                        class="mb-5"
                        label="labels.allergens_suitability"
                        name="allergens_suitability"
                        :entity="selectApiClientType.ALLERGENS_SUITABILITY"
                        :data-vv-as="$t('labels.allergens_suitability')"
                        :error-messages="
                            errors.collect('allergens_suitability')
                        "
                        multi-select
                        translate-items
                    ></entity-select-input>
                    <entity-select-input
                        v-model="model.allergens"
                        v-tab-error:menu
                        v-validate="''"
                        :invalid="invalidAllergens"
                        type="checkbox"
                        class="mb-5"
                        label="labels.allergens"
                        name="allergens"
                        :entity="selectApiClientType.ALLERGENS"
                        :data-vv-as="$t('labels.allergens')"
                        :error-messages="errors.collect('allergens')"
                        multi-select
                        translate-items
                    ></entity-select-input>
                    <entity-select-input
                        v-model="model.additional"
                        v-tab-error:menu
                        v-validate="''"
                        :invalid="invalidAdditionals"
                        type="checkbox"
                        class="mb-5"
                        label="labels.allergens_additional"
                        name="allergens_additional"
                        :entity="selectApiClientType.ALLERGENS_ADDITIONAL"
                        :data-vv-as="$t('labels.allergens_additional')"
                        :error-messages="errors.collect('allergens_additional')"
                        multi-select
                        translate-items
                    ></entity-select-input>
                </form-panel>

                <confirm-modal
                    v-model="dialog"
                    :title="$t('texts.are_you_sure')"
                    @onApprove="deleteMeal"
                ></confirm-modal>

                <portal to="footer">
                    <v-btn
                        v-if="!isRestaurantAdmin"
                        class="mr-4"
                        color="warning"
                        @click="dialog = true"
                    >
                        {{ $t('actions.delete') }}
                    </v-btn>
                    <v-btn color="success" :disabled="!isValid" @click="save">
                        {{ $t('actions.save') }}
                    </v-btn>
                </portal>
            </v-form>
        </div>
    </div>
</template>
