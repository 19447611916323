<script>
import SelectApiClientType from '@/api/SelectApiClientType'
import Config from '@/config.loader'

import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import FormThreeColRow from '@/components/form/FormThreeColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import CheckboxInput from '@/components/form/Inputs/CheckboxInput'

import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'
import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        TextInput,
        EntitySelectInput,
        CheckboxInput,
        FormThreeColRow,
    },
    inject: {
        $validator: 'formValidator',
    },
    mixins: [PhoneNumberMixin, FormTabMixin, FormInput],

    data() {
        return {
            selectApiClientType: SelectApiClientType,
            isVatRegistered: false,
            selfBilling: false,
        }
    },

    computed: {
        isUkAccount() {
            return Config.getConfigValue('VUE_APP_ACCOUNT_NR_LOCALE') === 'uk'
        },
        accountNumberValidateRule() {
            const rule = { space: true }
            rule.regex = this.isUkAccount
                ? /^[\d]{8}$/
                : /^[\d]{8}-[\d]{8}-[\d]{8}$/
            return rule
        },
    },

    watch: {
        'model.billingInfo.vatNumber'(vatNumber) {
            this.isVatRegistered = vatNumber !== null
        },

        'model.billingInfo.selfBilling'(selfBilling) {
            this.selfBilling = selfBilling !== null
        },

        isVatRegistered(isVatRegistered) {
            if (!isVatRegistered) {
                this.model.billingInfo.vatNumber = null
            }
        },

        selfBilling(selfBilling) {
            if (!selfBilling) {
                this.model.billingInfo.selfBilling = null
            }
        },
    },
}
</script>

<template>
    <!-- CONTRACTUAL TAB -->
    <v-tab-item
        key="contractual_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <form-panel :title="$t('labels.payment_terms')">
            <form-two-col-row>
                <template v-if="model.billingInfo" v-slot:col1>
                    <!-- PAYMENT TERM -->
                    <text-input
                        v-model="model.billingInfo.paymentTerm"
                        v-validate.immediate="'space'"
                        v-tab-error:contractual
                        name="billingInfo.paymentTerm"
                        label="labels.payment_term"
                        :error-messages="
                            errors.collect('billingInfo.paymentTerm')
                        "
                        :data-vv-as="$t('labels.payment_term')"
                    ></text-input>
                    <!-- accepted payment methods -->
                    <entity-select-input
                        v-model="model.acceptedPaymentMethodList"
                        v-validate.immediate="'required'"
                        v-tab-error:contractual
                        class="mt-2"
                        label="labels.accepted_payment_methods"
                        :data-vv-as="$t('labels.accepted_payment_methods')"
                        :entity="selectApiClientType.PAYMENT_METHODS"
                        name="acceptedPaymentMethodList"
                        autocomplete
                        :error-messages="
                            errors.collect('acceptedPaymentMethodList')
                        "
                        multi-select
                        translate-items
                    ></entity-select-input>
                </template>
                <template v-slot:col2>
                    <checkbox-input
                        v-model="selfBilling"
                        name="billingInfo.selfBilling"
                        label="labels.self_billing"
                    ></checkbox-input>
                    <text-input
                        v-show="selfBilling"
                        v-model="model.billingInfo.selfBilling"
                        class="mt-5"
                        name="billingInfo.selfBilling"
                        label="labels.self_billing"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>
        <!-- Registered entity -->
        <form-panel :title="$t('labels.registered_entity')">
            <form-two-col-row v-if="model.billingInfo">
                <template v-slot:col1>
                    <!-- COMPANY NAME -->
                    <text-input
                        v-model="model.billingInfo.regCompanyName"
                        v-tab-error:contractual
                        v-validate.immediate="'space'"
                        name="billingInfo.regCompanyName"
                        label="labels.company_name"
                        :error-messages="
                            errors.collect('billingInfo.regCompanyName')
                        "
                        :data-vv-as="$t('labels.company_name')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <!-- COMPANY REGISTRATION NO. -->
                    <text-input
                        v-model="model.billingInfo.registrationNumber"
                        v-validate.immediate="'space'"
                        v-tab-error:contractual
                        name="billingInfo.registrationNumber"
                        label="labels.company_registration_no"
                        :error-messages="
                            errors.collect('billingInfo.registrationNumber')
                        "
                        :data-vv-as="$t('labels.company_registration_no')"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>

        <!-- REGISTERED ADDRESS -->
        <form-panel
            v-if="model.billingInfo"
            :title="$t('labels.registered_address')"
        >
            <form-three-col-row>
                <template v-slot:col1>
                    <!-- COUNTRY -->
                    <text-input
                        v-model="model.billingInfo.regAddressCountry"
                        v-validate.immediate="'space'"
                        v-tab-error:contractual
                        name="billingInfo.regAddressCountry"
                        label="labels.country"
                        :error-messages="
                            errors.collect('billingInfo.regAddressCountry')
                        "
                        :data-vv-as="$t('labels.country')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <!-- CITY -->
                    <text-input
                        v-model="model.billingInfo.regAddressCity"
                        v-validate.immediate="'space'"
                        v-tab-error:contractual
                        name="billingInfo.regAddressCity"
                        label="labels.city"
                        :error-messages="
                            errors.collect('billingInfo.regAddressCity')
                        "
                        :data-vv-as="$t('labels.city')"
                    ></text-input>
                </template>
                <template v-slot:col3>
                    <!-- POSTCODE -->
                    <text-input
                        v-model="model.billingInfo.regAddressPostcode"
                        v-validate.immediate="'space'"
                        v-tab-error:contractual
                        name="billingInfo.regAddressPostcode"
                        label="labels.postcode"
                        :error-messages="
                            errors.collect('billingInfo.regAddressPostcode')
                        "
                        :data-vv-as="$t('labels.postcode')"
                    ></text-input>
                </template>
            </form-three-col-row>
            <form-three-col-row>
                <template v-slot:col1>
                    <!-- STREET NUMBER -->
                    <text-input
                        v-model="model.billingInfo.regAddressStreetNumber"
                        v-validate.immediate="'space'"
                        v-tab-error:contractual
                        name="billingInfo.regAddressStreetNumber"
                        label="labels.street_number"
                        :error-messages="
                            errors.collect('billingInfo.regAddressStreetNumber')
                        "
                        :data-vv-as="$t('labels.street_number')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <!-- STREET -->
                    <text-input
                        v-model="model.billingInfo.regAddressStreet"
                        v-validate.immediate="'space'"
                        v-tab-error:contractual
                        name="billingInfo.regAddressStreet"
                        label="labels.street"
                        :error-messages="
                            errors.collect('billingInfo.regAddressStreet')
                        "
                        :data-vv-as="$t('labels.street')"
                    ></text-input>
                </template>
            </form-three-col-row>
        </form-panel>

        <!-- BANK DETAILS -->
        <form-panel v-if="model.billingInfo" :title="$t('labels.bank_details')">
            <form-two-col-row>
                <template v-slot:col1>
                    <!-- ACCOUNT NUMBER -->
                    <text-input
                        v-model="model.billingInfo.accountNumber"
                        v-validate.immediate="accountNumberValidateRule"
                        v-tab-error:contractual
                        name="billingInfo.accountNumber"
                        label="labels.account_number"
                        :error-messages="
                            errors.collect('billingInfo.accountNumber')
                        "
                        :data-vv-as="$t('labels.account_number')"
                    ></text-input>
                </template>
                <template v-if="isUkAccount" v-slot:col2>
                    <!-- SORT CODE -->
                    <text-input
                        v-model="model.billingInfo.sortCode"
                        v-validate.immediate="{
                            space: true,
                            regex: /^[\d]{2}-[\d]{2}-[\d]{2}$/,
                        }"
                        v-tab-error:contractual
                        name="billingInfo.sortCode"
                        label="labels.sort_code"
                        :error-messages="errors.collect('billingInfo.sortCode')"
                        :data-vv-as="$t('labels.sort_code')"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>

        <!-- PROFILE INFORMATION -->
        <form-panel
            v-if="model.billingInfo"
            :title="$t('labels.profile_information')"
        >
            <form-three-col-row>
                <template v-slot:col1>
                    <!-- DELIVERY PROVIDER -->
                    <text-input
                        v-model="model.billingInfo.deliveryProvider"
                        v-validate.immediate="'space'"
                        v-tab-error:contractual
                        name="billingInfo.deliveryProvider"
                        label="labels.delivery_provider"
                        :error-messages="
                            errors.collect('billingInfo.deliveryProvider')
                        "
                        :data-vv-as="$t('labels.delivery_provider')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <!-- BRAND GROUPONG -->
                    <text-input
                        v-model="model.billingInfo.brandGrouping"
                        v-validate.immediate="'space'"
                        v-tab-error:contractual
                        name="billingInfo.brandGrouping"
                        label="labels.brand_grouping"
                        :error-messages="
                            errors.collect('billingInfo.brandGrouping')
                        "
                        :data-vv-as="$t('labels.brand_grouping')"
                    ></text-input>
                </template>
                <template v-slot:col3>
                    <!-- PREFERRED CONTACT CHANNEL -->
                    <text-input
                        v-model="model.billingInfo.preferredContactChannel"
                        v-validate.immediate="'space'"
                        v-tab-error:contractual
                        name="billingInfo.preferredContactChannel"
                        label="labels.preferred_contact_channel"
                        :error-messages="
                            errors.collect(
                                'billingInfo.preferredContactChannel'
                            )
                        "
                        :data-vv-as="$t('labels.preferred_contact_channel')"
                    ></text-input>
                </template>
            </form-three-col-row>
        </form-panel>

        <!-- VAT INFORMATION -->
        <form-panel :title="$t('labels.vat_information')">
            <form-two-col-row>
                <template v-slot:col1>
                    <checkbox-input
                        v-model="isVatRegistered"
                        label="labels.isVatRegistered"
                    ></checkbox-input>
                </template>
                <template v-if="model.billingInfo" v-slot:col2>
                    <!-- VAT NUMBER -->
                    <text-input
                        v-if="isVatRegistered"
                        v-model="model.billingInfo.vatNumber"
                        v-validate.immediate="
                            isVatRegistered ? 'required|space' : 'space'
                        "
                        v-tab-error:contractual
                        name="billingInfo.vatNumber"
                        label="labels.vat_number"
                        :error-messages="
                            errors.collect('billingInfo.vatNumber')
                        "
                        :data-vv-as="$t('labels.vat_number')"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>

        <!-- COMMISSION -->
        <form-panel v-if="model.billingInfo" :title="$t('labels.commission')">
            <div class="d-flex">
                <div class="mr-16">
                    <h4 class="mb-4">
                        {{ $t('labels.commission_ordit_delivery') }}
                    </h4>
                    <div>
                        <!-- COMMISSION ON PRODUCT VALUE -->
                        <text-input
                            v-model="model.billingInfo.commOnFood"
                            v-validate.immediate="'space|decimal'"
                            v-tab-error:contractual
                            name="billingInfo.commOnFood"
                            label="labels.commission_on_product_value"
                            :error-messages="
                                errors.collect('billingInfo.commOnFood')
                            "
                            :data-vv-as="
                                $t('labels.commission_on_product_value')
                            "
                            suffix="%"
                            type="number"
                        ></text-input>
                    </div>
                    <div>
                        <!-- COMMISSION ON DELIVERY FEE -->
                        <text-input
                            v-model="model.billingInfo.commOnDelivery"
                            v-validate.immediate="'space|decimal'"
                            v-tab-error:contractual
                            name="billingInfo.commOnDelivery"
                            label="labels.commission_on_delivery_fee"
                            :error-messages="
                                errors.collect('billingInfo.commOnDelivery')
                            "
                            :data-vv-as="
                                $t('labels.commission_on_delivery_fee')
                            "
                            suffix="%"
                            type="number"
                        ></text-input>
                    </div>
                    <div>
                        <!-- CARD TRANSACTION FEE -->
                        <text-input
                            v-model="model.billingInfo.cardTransactionFee"
                            v-validate.immediate="'space|decimal'"
                            v-tab-error:contractual
                            name="billingInfo.cardTransactionFee"
                            label="labels.card_transaction_fee"
                            :error-messages="
                                errors.collect('billingInfo.cardTransactionFee')
                            "
                            :data-vv-as="$t('labels.card_transaction_fee')"
                            suffix="%"
                            type="number"
                        ></text-input>
                    </div>
                </div>

                <div>
                    <h4 class="mb-4">
                        {{ $t('labels.commission_restaurant_delivery') }}
                    </h4>
                    <div>
                        <!-- COMMISSION ON PRODUCT VALUE -->
                        <text-input
                            v-model="model.billingInfo.restaurantCommOnFood"
                            v-validate.immediate="'space|decimal'"
                            v-tab-error:contractual
                            name="billingInfo.restaurantCommOnFood"
                            label="labels.commission_on_product_value"
                            :error-messages="
                                errors.collect(
                                    'billingInfo.restaurantCommOnFood'
                                )
                            "
                            :data-vv-as="
                                $t('labels.commission_on_product_value')
                            "
                            suffix="%"
                            type="number"
                        ></text-input>
                    </div>
                    <div>
                        <!-- COMMISSION ON DELIVERY FEE -->
                        <text-input
                            v-model="model.billingInfo.restaurantCommOnDelivery"
                            v-validate.immediate="'space|decimal'"
                            v-tab-error:contractual
                            name="billingInfo.restaurantCommOnDelivery"
                            label="labels.commission_on_delivery_fee"
                            :error-messages="
                                errors.collect(
                                    'billingInfo.restaurantCommOnDelivery'
                                )
                            "
                            :data-vv-as="
                                $t('labels.commission_on_delivery_fee')
                            "
                            suffix="%"
                            type="number"
                        ></text-input>
                    </div>
                    <div>
                        <!-- CARD TRANSACTION FEE -->
                        <text-input
                            v-model="
                                model.billingInfo.restaurantCardTransactionFee
                            "
                            v-validate.immediate="'space|decimal'"
                            v-tab-error:contractual
                            name="billingInfo.restaurantCardTransactionFee"
                            label="labels.card_transaction_fee"
                            :error-messages="
                                errors.collect(
                                    'billingInfo.restaurantCardTransactionFee'
                                )
                            "
                            :data-vv-as="$t('labels.card_transaction_fee')"
                            suffix="%"
                            type="number"
                        ></text-input>
                    </div>
                </div>
            </div>
        </form-panel>
    </v-tab-item>
</template>
